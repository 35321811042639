import {
  InvesterContactRoute_default
} from "./chunk-SFNFFMP2.js";
import {
  InvesterContactItemModel
} from "./chunk-GH3PD4LM.js";

// src/InvesterContact/InvesterContact.ts
var AesirxInvesterContactApiService = class {
  constructor() {
    this.route = null;
    this.route = new InvesterContactRoute_default();
  }
  /**
   * Create a Persona
   */
  async createInvesterContact(data) {
    try {
      const dataToSubmit = InvesterContactItemModel.__transformItemToApiOfCreation(data);
      const result = await this.route.createInvesterContactRequest(dataToSubmit);
      if (result) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  render() {
    return {};
  }
};

export {
  AesirxInvesterContactApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
