import {
  withMemberRoleViewModel
} from "./chunk-IUKEVJTX.js";
import {
  A
} from "./chunk-4COC2UV3.js";
import {
  AesirXSelectExtended
} from "./chunk-HMAYFD5S.js";
import {
  Spinner
} from "./chunk-POLRV4GK.js";
import {
  A as A2
} from "./chunk-NNGRNXS6.js";
import {
  history
} from "./chunk-KD2C3QY6.js";
import {
  notify
} from "./chunk-DL5QNKBI.js";

// src/pages/MemberRole/ListMemberRole.tsx
import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { observer } from "mobx-react";
var ListMemberRole = observer((props) => {
  const { t } = useTranslation();
  let listSelected = [];
  const viewModel = props.model.memberRoleListViewModel;
  useEffect(() => {
    viewModel.initializeData();
  }, []);
  const columnsTable = [
    {
      Header: t("txt_member_role_name"),
      accessor: "member_role",
      width: 150,
      className: "py-18 text-gray border-bottom-1 text-uppercase fw-semibold align-middle",
      Cell: ({ value }) => {
        return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center py-8px" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "mb-1" }, value?.name), /* @__PURE__ */ React.createElement("div", { className: "text-green" }, /* @__PURE__ */ React.createElement(
          "button",
          {
            onClick: () => {
              history.push(`/member-role/edit/${value?.id}`);
            },
            className: "p-0 border-0 bg-transparent d-inline-block text-green"
          },
          t("txt_edit")
        )))));
      }
    },
    {
      Header: t("txt_member_name"),
      accessor: "member",
      width: 150,
      className: "py-18 text-gray border-bottom-1 text-uppercase fw-semibold align-middle",
      Cell: ({ value }) => {
        return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center py-8px" }, /* @__PURE__ */ React.createElement("div", { className: "mb-1" }, value)));
      }
    },
    {
      Header: t("txt_role_name"),
      accessor: "role",
      width: 150,
      className: "py-18 text-gray border-bottom-1 text-uppercase fw-semibold align-middle",
      Cell: ({ value }) => {
        return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center py-8px" }, /* @__PURE__ */ React.createElement("div", { className: "mb-1" }, value)));
      }
    },
    {
      Header: t("txt_last_modified"),
      accessor: "lastModified",
      className: "py-18 text-gray border-bottom-1 text-uppercase fw-semibold align-middle",
      Cell: ({ value }) => {
        return /* @__PURE__ */ React.createElement("div", { className: "pe-2" }, /* @__PURE__ */ React.createElement("div", { className: "mb-1" }, viewModel?.successResponse?.listPublishStatus?.find(
          (o) => o.value == value.status
        )?.label && t(
          "txt_" + viewModel?.successResponse?.listPublishStatus.find((o) => o.value == value.status)?.label?.toString().toLowerCase()
        )), /* @__PURE__ */ React.createElement("div", null, value.dateTime, " ", t("txt_by"), " ", value.author));
      }
    }
  ];
  const currentSelectHandler = (arr) => {
    listSelected = arr.map((o) => o.cells[1]?.value?.id);
  };
  const deleteMemberRoles = () => {
    if (listSelected.length < 1) {
      notify(t("txt_row_select_error"), "error");
    } else {
      viewModel.isLoading();
      viewModel.deleteMemberRoles(listSelected);
    }
  };
  const selectPageHandler = (value) => {
    if (value != viewModel.successResponse.pagination.page) {
      viewModel.isLoading();
      viewModel.getListByFilter(
        "list[start]",
        (value - 1) * viewModel.successResponse.pagination.pageLimit
      );
    }
  };
  const selectShowItemsHandler = (value) => {
    viewModel.isLoading();
    viewModel.getListByFilter("list[limit]", value?.value);
  };
  const selectTabHandler = (value) => {
    viewModel.isLoading();
    if (value != "default") {
      viewModel.getListByFilter("filter[published]", value);
    } else {
      viewModel.getListByFilter("filter[published]", "");
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "px-3 py-4" }, /* @__PURE__ */ React.createElement("div", { className: "mb-3 d-flex align-items-center justify-content-between" }, /* @__PURE__ */ React.createElement("h2", { className: "fw-bold" }, t("txt_left_menu_member_role")), /* @__PURE__ */ React.createElement(
    A2,
    {
      buttons: [
        {
          title: t("txt_delete"),
          icon: "/assets/images/delete.svg",
          iconColor: "#cb222c",
          textColor: "#cb222c",
          handle: async () => {
            deleteMemberRoles();
          }
        },
        {
          title: t("txt_add_new"),
          icon: "/assets/images/plus.svg",
          variant: "success",
          handle: async () => {
            history.push("/member-role/add");
          }
        }
      ]
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "mb-3" }, /* @__PURE__ */ React.createElement(
    Tabs,
    {
      defaultActiveKey: "memberRoleList",
      id: "tab-setting",
      onSelect: (k) => selectTabHandler(k)
    },
    /* @__PURE__ */ React.createElement(Tab, { key: "memberRoleList", eventKey: "memberRoleList", title: t("txt_all_member_role") })
  )), /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center justify-content-between gap-2 my-20" }, /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center" }, /* @__PURE__ */ React.createElement("div", { className: "text-gray me-2" }, t("txt_showing")), /* @__PURE__ */ React.createElement(
    AesirXSelectExtended,
    {
      defaultValue: {
        label: `${viewModel?.successResponse?.filters["list[limit]"]} ${t("txt_items")}`,
        value: viewModel?.successResponse?.filters["list[limit]"]
      },
      options: [...Array(9)].map((o, index) => ({
        label: `${(index + 1) * 10} ${t("txt_items")}`,
        value: (index + 1) * 10
      })),
      onChange: (o) => selectShowItemsHandler(o),
      className: `fs-sm bg-white shadow-sm rounded-2`,
      isBorder: true,
      placeholder: `Select`,
      arrowColor: "var(--dropdown-indicator-color)",
      size: "large"
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "bg-white rounded" }, viewModel?.successResponse?.state ? /* @__PURE__ */ React.createElement(
    A,
    {
      classNameTable: `bg-white rounded table-striped table`,
      columns: columnsTable,
      data: viewModel?.successResponse?.listMemberRoles,
      pagination: viewModel?.successResponse?.pagination,
      selection: false,
      selectPage: selectPageHandler,
      currentSelect: currentSelectHandler
    }
  ) : /* @__PURE__ */ React.createElement(Spinner, null)));
});
var ListMemberRole_default = withTranslation()(withMemberRoleViewModel(ListMemberRole));

export {
  ListMemberRole_default
};
