import {
  FORMAT_DATE_TIME_UPDATE_POST
} from "./chunk-IIJXEE2D.js";
import {
  ComponentSVG
} from "./chunk-APR4SZIP.js";

// src/components/CustomizedDatePicker/index.tsx
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

// src/components/CustomizedDatePicker/index.scss
var css = `.react-datepicker-popper {
  z-index: 2;
}
.react-datepicker-popper .react-datepicker__triangle {
  left: -16px !important;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/CustomizedDatePicker/index.tsx
var CustomizedDatePicker = ({
  handleOnChange,
  defaultDate,
  dateFormat,
  isDisabled,
  showTimeSelect,
  placeholderText,
  isUTC,
  disablePast = false,
  changeYear = false,
  minDate,
  maxDate,
  isClearable = false
}) => {
  const [startDate, setStartDate] = useState();
  useEffect(() => {
    defaultDate && setStartDate(
      isUTC ? new Date(moment(defaultDate).utc().format(FORMAT_DATE_TIME_UPDATE_POST)) : new Date(moment(defaultDate).format(FORMAT_DATE_TIME_UPDATE_POST))
    );
  }, [defaultDate]);
  return /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center bg-white position-relative date-picker" }, /* @__PURE__ */ React.createElement("div", { className: "calendar-icon calendar-icon-start position-absolute top-50 translate-middle-y d-flex align-items-center" }, /* @__PURE__ */ React.createElement(ComponentSVG, { url: "/assets/images/clock.svg", color: "#C0C0C0" })), /* @__PURE__ */ React.createElement(
    DatePicker,
    {
      dateFormat: dateFormat ?? "MMM d, yyyy",
      selected: startDate,
      wrapperClassName: "w-100",
      onChange: (date) => {
        handleOnChange(date);
        setStartDate(date);
      },
      showTimeSelect,
      adjustDateOnChange: true,
      className: `ps-40 m-0 border-0 outline-none position-relative border-1 rounded-1 ${isDisabled ? "bg-gray-300 text-body" : "bg-white text-body"}`,
      readOnly: isDisabled,
      placeholderText: placeholderText ?? dateFormat ?? null,
      minDate: disablePast ? moment().toDate() : minDate ? minDate : null,
      maxDate: maxDate ? maxDate : null,
      showYearDropdown: changeYear,
      dropdownMode: "select",
      isClearable
    }
  ));
};

export {
  CustomizedDatePicker
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
