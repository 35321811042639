import {
  ProfileStore
} from "./chunk-ZXCN2AFP.js";
import {
  logout
} from "./chunk-NDIHI5BU.js";
import {
  PAGE_STATUS
} from "./chunk-OSVSI4BW.js";
import {
  notify
} from "./chunk-DL5QNKBI.js";
import {
  __publicField
} from "./chunk-JCO6WRHX.js";

// src/pages/Profile/model.tsx
import { makeAutoObservable, runInAction } from "mobx";
import React, { createContext, useContext } from "react";
import { Storage } from "aesirx-lib";
var ProfileModel = class {
  constructor(profileStore2) {
    __publicField(this, "profileStore");
    __publicField(this, "formStatus", PAGE_STATUS.READY);
    __publicField(this, "updateGeneralViewModel", null);
    __publicField(this, "data", {});
    __publicField(this, "successResponse", {
      state: true,
      content_id: ""
    });
    __publicField(this, "init", async (id) => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.profileStore.getMember(id);
      runInAction(() => {
        if (data) {
          this.data = data;
          this.formStatus = PAGE_STATUS.READY;
        }
      });
    });
    __publicField(this, "save", async (data) => {
      await notify(this.profileStore.updateProfile(data), "promise");
    });
    __publicField(this, "savePreregistration", async (jwt, data) => {
      await this.profileStore.updatePreregistration(jwt, data);
    });
    __publicField(this, "getData", () => {
      return this.data;
    });
    __publicField(this, "savePassword", async (data) => {
      const rs = await this.profileStore.updatePassword(data);
      runInAction(() => {
        this.successResponse.state = rs?.result?.success;
        this.successResponse.content_id = rs?.result?.content_id;
      });
      if (rs?.result?.success) {
        logout();
        notify("Change password successfully, please re-login with your new password.", "success");
      }
    });
    makeAutoObservable(this);
    this.profileStore = profileStore2;
  }
};
var profileStore = new ProfileStore();
var profileModel = new ProfileModel(profileStore);
var ProfileContext = createContext({ model: profileModel });
var ProfileContextProvider = ({ children }) => {
  profileModel.init(parseInt(String(Storage.getItem("member_id"))));
  return /* @__PURE__ */ React.createElement(ProfileContext.Provider, { value: { model: profileModel } }, children);
};
var useProfileContext = () => useContext(ProfileContext);
var withProfileContext = (Component) => (props) => {
  return /* @__PURE__ */ React.createElement(Component, { ...props, ...useProfileContext() });
};

export {
  ProfileContextProvider,
  useProfileContext,
  withProfileContext
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
