import {
  Instance_default
} from "./chunk-GZ3JSDB6.js";
import {
  Utils_default
} from "./chunk-TLJMJLS5.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";

// src/Pim/PimBrand/Route.ts
var BrandRouter = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.option = "reditem-pim_brand";
    this.getList = (filters) => {
      const buildFilters = Utils_default.createFilters(filters);
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          ...buildFilters
        })
      );
    };
    this.getDetail = (id = 0, dataFilter = {}) => {
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          id,
          ...dataFilter
        })
      );
    };
    this.create = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: this.option
        }),
        data
      );
    };
    this.update = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: this.option
        }),
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
    };
    this.delete = (ids) => {
      return Instance_default.delete(
        this.createRequestURL({
          option: this.option
        }),
        {
          data: { ids: Array.isArray(ids) ? ids : [ids] }
        }
      );
    };
  }
};
var Route_default = BrandRouter;

export {
  Route_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
