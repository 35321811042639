import {
  MemberRoleViewModel_default
} from "./chunk-RZD5DZND.js";
import {
  MemberRoleStore
} from "./chunk-VLR4SFUO.js";

// src/pages/MemberRole/MemberRoleViewModel/MemberRoleViewModelContextProvider.tsx
import React from "react";
var memberRoleStore = new MemberRoleStore();
var roleViewModel = new MemberRoleViewModel_default(memberRoleStore);
var MemberRoleViewModelContext = React.createContext({
  model: roleViewModel
});
var MemberRoleViewModelContextProvider = ({ children }) => {
  return /* @__PURE__ */ React.createElement(MemberRoleViewModelContext.Provider, { value: { model: roleViewModel } }, children);
};
var useMemberRoleViewModel = () => React.useContext(MemberRoleViewModelContext);
var withMemberRoleViewModel = (Component) => (props) => {
  return /* @__PURE__ */ React.createElement(Component, { ...props, ...useMemberRoleViewModel() });
};

export {
  MemberRoleViewModelContext,
  MemberRoleViewModelContextProvider,
  useMemberRoleViewModel,
  withMemberRoleViewModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
