import {
  PAGE_STATUS
} from "./chunk-OSVSI4BW.js";
import {
  notify
} from "./chunk-DL5QNKBI.js";
import {
  __publicField
} from "./chunk-JCO6WRHX.js";

// src/pages/Permission/PermissionViewModel/PermissionListViewModel.ts
import { makeAutoObservable, runInAction } from "mobx";
import { PERMISSION_FIELD } from "aesirx-lib";
var PermissionListViewModel = class {
  constructor(permissionStore) {
    __publicField(this, "permissionStore");
    __publicField(this, "formStatus", PAGE_STATUS.READY);
    __publicField(this, "permissionListViewModel", {});
    __publicField(this, "formPropsData", [{}]);
    __publicField(this, "items", []);
    __publicField(this, "filter", {});
    __publicField(this, "successResponse", {
      state: false,
      content_id: "",
      listPublishStatus: [],
      1: [],
      filters: {
        "list[limit]": 10
      },
      listPermission: [],
      pagination: null,
      listPermissionWithoutPagination: []
    });
    __publicField(this, "setForm", (permissionListViewModel) => {
      this.permissionListViewModel = permissionListViewModel;
    });
    __publicField(this, "initializeData", async () => {
      runInAction(() => {
        this.successResponse.state = false;
      });
      const data = await this.permissionStore.getList(this.successResponse.filters);
      runInAction(() => {
        if (!data?.error) {
          this.onSuccessHandler(data?.response, "");
        } else {
          this.onErrorHandler(data?.response);
        }
      });
    });
    __publicField(this, "initializeAllData", async () => {
      runInAction(() => {
        this.successResponse.state = false;
      });
      const data = await this.permissionStore.getListWithoutPagination(this.filter);
      runInAction(() => {
        if (!data?.error) {
          this.callbackOnSuccessGetPermissionHandler(data?.response);
        } else {
          this.onErrorHandler(data?.response);
        }
        this.successResponse.state = true;
      });
    });
    __publicField(this, "getListByFilter", async (key, value) => {
      value ? this.successResponse.filters[key] = value : delete this.successResponse.filters[key];
      if (key != "list[start]" && key != "list[limit]") {
        delete this.successResponse.filters["list[start]"];
      } else {
        if (key == "list[limit]" && value * this.successResponse.pagination.page >= this.successResponse.pagination.totalItems) {
          this.successResponse.filters["list[start]"] = Math.ceil(this.successResponse.pagination.totalItems / value - 1) * value;
        } else if (key == "list[limit]" && value * this.successResponse.pagination.page < this.successResponse.pagination.totalItems) {
          this.successResponse.filters["list[start]"] = (this.successResponse.pagination.page - 1) * value;
        }
      }
      const data = await this.permissionStore.getList(this.successResponse.filters);
      runInAction(() => {
        if (!data?.error) {
          this.onSuccessHandler(data?.response, "");
        } else {
          this.onErrorHandler(data?.response);
        }
        this.successResponse.state = true;
      });
    });
    __publicField(this, "onSuccessHandler", (result, message) => {
      if (result && message) {
        notify(message, "success");
      }
      if (result?.listItems) {
        this.successResponse.listPermission = this.transform(result?.listItems);
        this.successResponse.pagination = result?.pagination;
        this.items = result?.listItems;
        this.successResponse.state = true;
      }
      if (result?.listPublishStatus) {
        this.successResponse.listPublishStatus = result?.listPublishStatus;
      }
    });
    __publicField(this, "onErrorHandler", (error) => {
      error._messages[0]?.message ? notify(error._messages[0]?.message, "error") : error.message && notify(error.message, "error");
      this.successResponse.state = false;
      this.successResponse.content_id = error.result;
      this.formStatus = PAGE_STATUS.READY;
    });
    __publicField(this, "transform", (data) => {
      return data.map((o) => {
        return {
          permission: {
            id: o[PERMISSION_FIELD.ID],
            name: o[PERMISSION_FIELD.ROLE_NAME]
          },
          create: "",
          edit: "",
          delete: "",
          subRows: Object.keys(o[PERMISSION_FIELD.RULES]).map((key) => ({
            permission: {
              id: o[PERMISSION_FIELD.ID],
              name: key ?? ""
            },
            asset_id: o[PERMISSION_FIELD.RULES][key][PERMISSION_FIELD.ASSET_ID],
            entity: o[PERMISSION_FIELD.RULES][key][PERMISSION_FIELD.ENTITY],
            group_id: o[PERMISSION_FIELD.GROUP_ID],
            create: o[PERMISSION_FIELD.RULES][key]?.permission?.create,
            edit: o[PERMISSION_FIELD.RULES][key]?.permission?.edit,
            delete: o[PERMISSION_FIELD.RULES][key]?.permission?.delete
          }))
          // organisation: o[PERMISSION_FIELD.ORGANISATION],
          // lastModified: {
          //   status: o[PERMISSION_FIELD.PUBLISHED],
          //   dateTime: date ?? '',
          //   author: o[PERMISSION_FIELD.CREATED_USER_NAME],
          // },
          // published: {
          //   state: o[PERMISSION_FIELD.PUBLISHED],
          //   id: o[PERMISSION_FIELD.ID],
          // },
        };
      });
    });
    __publicField(this, "deletePermission", async (arr) => {
      const data = await this.permissionStore.delete(arr);
      runInAction(async () => {
        if (!data?.error) {
          await this.initializeData();
          this.onSuccessHandler(data?.response, "Deleted successfully");
        } else {
          this.onErrorHandler(data?.response);
        }
        this.successResponse.state = true;
      });
    });
    __publicField(this, "setPublished", async ({ id, name }, state = 0) => {
      const data = await this.permissionStore.update({
        id: id.toString(),
        permission_name: name,
        published: state.toString()
      });
      runInAction(async () => {
        if (!data?.error) {
          await this.initializeData();
          this.onSuccessHandler(data?.response, "Updated successfully");
        } else {
          this.onErrorHandler(data?.response);
        }
      });
    });
    __publicField(this, "isLoading", () => {
      runInAction(() => {
        this.successResponse.state = false;
      });
    });
    __publicField(this, "callbackOnSuccessGetPermissionHandler", (result) => {
      this.successResponse.listPermissionWithoutPagination = result?.listItems?.map((o) => {
        let dash = "";
        for (let index = 1; index < o.level; index++) {
          dash += "- ";
        }
        return {
          value: o?.id,
          label: `${dash}${o[PERMISSION_FIELD.ROLE_NAME]}`
        };
      });
    });
    __publicField(this, "update", async () => {
      const data = await this.permissionStore.update(this.formPropsData);
      runInAction(() => {
        if (!data?.error) {
          this.onUpdateSuccessHandler(data?.response, "Updated successfully");
        } else {
          this.onUpdateErrorHandler(data?.response);
        }
      });
      return data;
    });
    __publicField(this, "handleFormPropsData", (key, value) => {
      if (key && value !== null) {
        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
          Object.assign(this.formPropsData[key], value);
        } else {
          this.formPropsData[key] = value;
        }
      }
    });
    __publicField(this, "onUpdateErrorHandler", (error) => {
      error._messages[0]?.message ? notify(error._messages[0]?.message, "error") : error.message && notify(error.message, "error");
    });
    __publicField(this, "onUpdateSuccessHandler", (result, message) => {
      if (result && message) {
        notify(message, "success");
      }
      this.formStatus = PAGE_STATUS.READY;
    });
    makeAutoObservable(this);
    this.permissionStore = permissionStore;
  }
};
var PermissionListViewModel_default = PermissionListViewModel;

export {
  PermissionListViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
