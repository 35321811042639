import {
  MemberListViewModel_default
} from "./chunk-RQQSKPZF.js";
import {
  MemberDetailViewModel_default
} from "./chunk-V6T3NL2T.js";
import {
  __publicField
} from "./chunk-JCO6WRHX.js";

// src/pages/Members/MemberViewModel/MemberViewModel.ts
var MemberViewModel = class {
  constructor(memberStore) {
    __publicField(this, "memberDetailViewModel", {});
    __publicField(this, "memberListViewModel", {});
    __publicField(this, "getMemberDetailViewModel", () => this.memberDetailViewModel);
    __publicField(this, "getMemberListViewModel", () => this.memberListViewModel);
    if (memberStore) {
      this.memberDetailViewModel = new MemberDetailViewModel_default(memberStore);
      this.memberListViewModel = new MemberListViewModel_default(memberStore);
    }
  }
};
var MemberViewModel_default = MemberViewModel;

export {
  MemberViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
